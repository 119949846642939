import React, { useState } from "react";
import Highlight, { defaultProps } from "prism-react-renderer";
import styled from "@emotion/styled";
import { LiveProvider, LiveEditor, LiveError, LivePreview } from "react-live";
import liveTheme from "prism-react-renderer/themes/shadesOfPurple";
import theme from "prism-react-renderer/themes/dracula";

import Icons from "../../icons";
import mediaqueries from "../../styles/media";
import { copyToClipboard } from "../..//utils";
import { LangsIcon, preStyles } from "./Language";

const RE = /{([\d,-]+)}/;

function calculateLinesToHighlight(meta) {
  if (RE.test(meta)) {
    const lineNumbers = RE.exec(meta)[1]
      .split(",")
      .map((v) => v.split("-").map((y) => parseInt(y, 10)));

    return (index) => {
      const lineNumber = index + 1;
      const inRange = lineNumbers.some(([start, end]) =>
        end ? lineNumber >= start && lineNumber <= end : lineNumber === start
      );
      return inRange;
    };
  } else {
    return () => false;
  }
}

function CodePrism({ codeString, language, metastring, ...props }) {
  const shouldHighlightLine = calculateLinesToHighlight(metastring);

  if (props["live"]) {
    return (
      <Container>
        <LiveProvider code={codeString} noInline={true} theme={liveTheme}>
          <LiveEditor style={{ marginBottom: "3px", borderRadius: "2px" }} />
          <LivePreview style={{ fontSize: "18px", borderRadius: "2px" }} />
          <LiveError style={{ color: "tomato" }} />
        </LiveProvider>
      </Container>
    );
  } else {
    return (
      <Highlight
        {...defaultProps}
        code={codeString}
        language={language}
        theme={theme}
      >
        {({ className, tokens, getLineProps, getTokenProps }) => {
          return (
            <div style={{ overflow: "auto" }}>
              <LangsIcon>
                <pre className={className} style={{ position: "relative" }}>
                  <Copy toCopy={codeString} />
                  {tokens.map((line, index) => {
                    const { className } = getLineProps({
                      line,
                      key: index,
                      className: shouldHighlightLine(index)
                        ? "highlight-line"
                        : "",
                    });

                    return (
                      <div key={index} className={className}>
                        <span className="number-line">{index + 1}</span>
                        {line.map((token, key) => {
                          const { className, children } = getTokenProps({
                            token,
                            key,
                          });

                          return (
                            <span key={key} className={className}>
                              {children}
                            </span>
                          );
                        })}
                      </div>
                    );
                  })}
                </pre>
              </LangsIcon>
            </div>
          );
        }}
      </Highlight>
    );
  }
}

export default CodePrism;

function Copy({ toCopy }: { toCopy: string }) {
  const [hasCopied, setHasCopied] = useState<boolean>(false);

  function copyToClipboardOnClick() {
    if (hasCopied) return;

    copyToClipboard(toCopy);
    setHasCopied(true);

    setTimeout(() => {
      setHasCopied(false);
    }, 1800);
  }

  return (
    <CopyButton onClick={copyToClipboardOnClick} data-a11y="false">
      {hasCopied ? (
        <>
          Copied <Icons.Copied fill="#eee" />
        </>
      ) : (
        <>
          Copy <Icons.Copy fill="#eee" />
        </>
      )}
    </CopyButton>
  );
}

const CopyButton = styled.button`
  position: absolute;
  right: 7px;
  top: -2px;
  padding: 8px 12px 7px;
  border-radius: 5px;
  color: #eee;
  transition: background 0.3s ease;
  &:hover {
    background: rgba(255, 255, 255, 0.07);
  }
  &[data-a11y="true"]:focus::after {
    content: "";
    position: absolute;
    left: -2%;
    top: -2%;
    width: 100%;
    height: 100%;
    /* border: 2px solid ${(p) => p.theme.colors.accent}; */
    border-radius: 5px;
    background: rgba(255, 255, 255, 0.01);
  }
  /* ${mediaqueries.tablet`
    display: none;
  `} */
`;

const Container = styled.div`
  overflow: scroll;
  width: 100%;
  max-width: 750px;
  margin: 0 auto;
  font-size: 14px;
  margin: 15px auto 50px;
  border-radius: 5px;
  font-family: ${(p) => p.theme.fonts.monospace} !important;
  textarea,
  pre {
    padding: 32px !important;
    font-family: ${(p) => p.theme.fonts.monospace} !important;
  }
  ${mediaqueries.desktop`
      left: -26px;
    `};
  ${mediaqueries.tablet`
    max-width: 526px;
    left: 0;
    textarea,
    pre {
      padding: 20px !important;
    }
  `};
  ${mediaqueries.phablet`
    border-radius: 0;
    margin: 0 auto 25px;
    overflow: initial;
    width: unset;
    max-width: unset;
    float: left;
    min-width: 100%;
    overflow: initial;
    position: relative;
  `};
`;
